@import '../../../styles/cssColors.scss';
.cursorIcon {
	cursor: pointer;
}

.root {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px;

	margin-bottom: 24px;
	background: $stdColor-White;
	border-radius: 3px;
	border: 1px solid #efefef;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.pageSizeSelectotLabel {
	font-size: 16px;
	line-height: 38px;
}

.pageSelectorWrapper {
	display: flex;
	align-items: center;
}
