@import '../../../styles/cssColors.scss';
.root {
	display: inline-block;
	width: 100%;

	padding: 8px 35px 8px 20px;
	align-items: center;
	justify-content: left;
	cursor: pointer;
}

.wrapper {
	display: flex;
	height: 35px;
	align-items: center;
	text-align: center;
	font-size: 15px;
	gap: 5px;
}
.wrapper:active {
	color: #d8d8d8;
}

.icon {
	line-height: 35px !important;
	width: 35px !important;
	font-size: 30px !important;
	color: $stdColor-Primary;
}

.content {
	color: $stdColor-Primary;
	font-weight: 700;
	text-decoration: none !important;
}

a:link,
a:visited,
a:hover,
a:active {
	color: $stdColor-Primary;
	text-decoration: none !important;
}
