@import '../../styles/cssSizeing.scss';

.BasePage {
	display: flex;
	height: 100vh;
	width: 100vw;
}

.ContentContainer {
	display: flex;
	height: 100vh;
}

.SidebarContainer {
	width: $menuBarLeftWidth;
	overflow: auto;
}

.ContentFrame {
	display: flex;
	flex-direction: column;

	left: menuBarLeftWidth;
	width: calc(100vw - $menuBarLeftWidth);
	overflow: auto;
	max-width: 1000px;
	margin: 0 auto;
}
