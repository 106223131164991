@import '../../common/styles/cssColors.scss';

.Root {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}
.Question {
	margin-bottom: 4px;
}
