@import '../../../styles/cssColors.scss';

.HeaderContainer {
	margin: 0;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	padding: 16px 24px;

	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}
.HeaderTitle {
	color: $stdColor-Primary;
	margin: 0;
	line-height: 1.3em;
	font-weight: bold;
}

.ContentContainer {
	display: flex;
	flex-direction: column;
	grid-gap: 2px;
}

.ActionsContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
