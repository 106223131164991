@import '../../../styles/cssColors.scss';

.root {
	display: flex;
	flex-direction: column;
	padding: 24px 24px 24px 24px;
	background: $stdColor-White;
	border-radius: 3px;
	border: 1px solid #efefef;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.loadingWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.loadingIcon {
	width: 60px;
	height: 60px;
}
