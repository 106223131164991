@import '../../../styles/cssColors.scss';

.root {
	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
	background: $stdColor-Tertiar;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 150px;
	border-bottom: 2px solid $stdColor-Primary; //rgba(179, 184, 212, 0.2)
}
.headerImage {
	width: 200px;
	margin: 10px;
}

.content {
	flex-grow: 1;
}

.footer {
	height: 40px;
	border-bottom: 2px solid $stdColor-Primary; //rgba(179, 184, 212, 0.2)
}

.groupMenuItems {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;
}
