@import '../../../styles/cssColors.scss';

.root {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.textBelow {
	flex-direction: column;
	align-items: flex-start;
}
.title {
	color: #179e97;
	font-size: 15px;
	line-height: 1.3em;
	font-weight: bold;
	padding-right: 5px;
}
