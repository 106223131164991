@import './common/styles/cssColors.scss';
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #eee !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mousePointer {
	cursor: pointer;
}

.stdButton {
	max-width: 150px;
}

.loginForm {
	width: 450px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $stdColor-Tertiar;
	text-align: center;
}
.loginFormContainer {
	padding: 40px;
}
.loginFormHeader {
	font-size: 2rem;
	color: $stdColorText-White;
	margin-bottom: 1rem;
}
.loginImage {
	width: 200px;
	margin: 10px;
}
.loginFormText {
	color: $stdColorText-White;
	margin-bottom: 1rem;
}
.loginFormField {
	//background: none;
	display: flex;
	flex-direction: column;
	margin: 20px auto;
	text-align: center;
	border: 4px solid #179e97;
	padding: 14px 10px;
	width: 80%;
	outline: none;
	color: $stdColor-White;
	border-radius: 24px;
	transition: 0.25s;
	color: black;
	font-weight: bold;
	font-size: medium;
}
.loginFormButton {
	color: $stdColor-White;
	width: 100%;
	font-size: 1.4rem;
	background: #179e97;
	border-radius: 24px;
	border: none;
	cursor: pointer;
	padding: 0.4rem 1.2rem;
	margin-top: 0.5rem;

	&:hover {
		background: $stdColor-Primary;
	}
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.MuiTabs-indicator {
	background-color: $stdColor-Primary !important;
}

// User register page
#adminSettings {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.form-group {
	color: white;
}

.bold {
	font-weight: bold;
}

// Config

// General DOM selector styles

// Fonts & General Type Styling

// 3rd Party Addons

// Atomic Design
