@import '../../../styles/cssColors.scss';

.root {
	display: flex;
	flex-direction: column;
	padding: 24px 10px 24px 24px;
	margin-bottom: 24px;
	background: $stdColor-White;
	border-radius: 3px;
	border: 1px solid #efefef;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	height: 48px !important;

	margin-right: 8px;
	margin-bottom: 5px;
}
.cursorPointer {
	cursor: pointer;
}
@media screen and (max-width: 600px) {
}
