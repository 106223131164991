@import '../../../styles/cssColors.scss';

.header {
	display: block;
}
.headerTitle {
	color: $stdColor-Primary;
	margin-bottom: 20px;
	line-height: 1.3em;
	font-weight: bold;
}
